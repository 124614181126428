<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <Loader :visible="isLoading" />
    <v-container fluid class="ma-0 pa-0" v-if="!isLoading">
      <v-row>
        <v-col cols="12" md="4">
          <div class="px-3">
            {{ dimension.name }}
          </div>
        </v-col>
        <v-col cols="12" md="8" v-if="desktop">
          <div class="px-3">Recomendaciones por componentes de {{ dimension.name }}</div>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" md="4">
          <Sumary :score="dimension.score" :level="dimension.levelName" />
        </v-col>
        <v-col cols="12" md="8">
          <Recommendations :subdimensions="dimension.subDimensions" :dimension-code="dimension.code" />
        </v-col>
      </v-row>
      <v-container fluid>
        <v-card class="pa-6" :height="desktop ? '150px' : '100%'">
          <v-row justify="space-between">
            <v-col cols="12" md="2">
              <div class="mt-n4">Hoja de Ruta</div>
              <div>{{ dimension.name }}</div>
            </v-col>
            <v-col cols="12" md="8">
              <Timeline :value="dimension.score" />
            </v-col>
            <v-col cols="12" md="2">
              <v-row justify="center" class="align-start">
                <v-img :src="require('@/assets/img/dimension-img.svg')" class="rounded" max-width="55%" />
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <div class="px-3">Desafíos Estratégicos</div>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" md="4" v-for="(dimension, index) in challengeDimensions" :key="index">
          <Challenge :dimension="dimension" @dimensionId="dimensionId = $event" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Timeline from "@/components/dashboards/IMTD/Timeline";
import Sumary from "@/components/dashboards/IMTD/dashboardDimension/sumary";
import Recommendations from "@/components/dashboards/IMTD/dashboardDimension/recommendations";
import Challenge from "@/components/dashboards/IMTD/dashboardDimension/challenge";
import Loader from "@/components/Loader";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DashboardDimension",

  components: {
    Timeline,
    Sumary,
    Recommendations,
    Challenge,
    Loader,
    Breadcrumbs,
  },

  data: () => ({
    isLoading: true,
    breadcrumbs: [
      {
        text: "Inicio",
        disabled: false,
        exact: true,
        to: {
          name: "home",
        },
      },
      {
        text: "Dashboard TD",
        disabled: false,
        exact: true,
        to: {
          name: "dashboard",
        },
      },
      {
        text: "",
        disabled: true,
        exact: true,
        to: {
          name: "dashboard",
        },
      },
    ],
    dimensionId: "",
    dimension: { name: "" },
    challengeDimensions: [],
  }),
  async created() {
    if (this.getDimensions.length == 0) {
      await this.setRecommendations({ clientId: this.getClientId });
    }
    this.dimensionId = this.$route.params.id;
    this.changeDimension(this.dimensionId);
    this.isLoading = false;
  },
  methods: {
    ...mapActions("ImtdStore", ["setRecommendations"]),
    changeDimension(code) {
      this.dimension = this.getDimensions.find((e) => e.code == code);
      this.breadcrumbs[2].text = this.dimension.name;
      let container = document.getElementById("main-scroll");
      container.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    dimensionId() {
      this.changeDimension(this.dimensionId);
      this.challengeDimensions = this.getDimensions.filter((element) => element.code != this.dimensionId);
    },
  },
  computed: {
    ...mapGetters("UserStore", ["getClientId"]),
    ...mapGetters("ImtdStore", ["getDimensions"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
<style scoped lang="scss">
.dimension-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}

.small-btn-text-1 {
  font-size: 10px;
  color: #7319d5;
}

.small-btn-text-2 {
  font-size: 10px;
  color: #999999;
}

.center-text {
  text-align: center !important;
}

.title-idx-text {
  font-size: 23px;
  width: 100%;
  .element {
    text-transform: lowercase;
  }
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}

.title-idx-small {
  font-size: 14px;
  .element {
    text-transform: lowercase;
  }
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}

.title-idx-subtitle {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  text-align: start;
  color: #7319d5;
}

.item-title {
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  font-weight: 400;
  text-align: start;
  cursor: pointer;
}

.item-title:hover,
.item-title:focus {
  color: rgba(#000, 0.6);
}

.item-score {
  font-size: 30px;
  width: 100%;
  line-height: 14px;
  font-weight: 400;
  color: #333333;
  text-align: start;
}

.item-score-danger {
  font-size: 30px;
  width: 100%;
  color: #b71c1c;
  line-height: 14px;
  font-weight: 400;
  text-align: start;
}

.center-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.full-height {
  height: 100%;
}

.border-bottom {
  border-bottom: 1px solid #dedede;
}

.worse-title {
  color: #7319d5;
  text-transform: lowercase;
}

.worse-text {
  font-size: 16px;
}

.pertentage-text-danger {
  color: #b71c1c;
  font-size: 50px;
  line-height: 82.03px;
  text-align: center;
  font-weight: 700;
}

.dimension-tabs {
  border-radius: 2px 2px 0 0;
}

.category-item {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
</style>
