<template>
  <v-card height="100%" class="pa-3">
    <v-row width="100%" justify="center" align="center" class="mb-6 ma-0 pa-0 title-card-text"> Índice </v-row>
    <v-row width="100%" justify="center" align="center" class="mb-6 ma-0 pa-0 tway-violet--text">
      <v-progress-circular :rotate="-90" :size="180" :width="22" :value="Math.round(score)" color="#16C62E">
        <span class="roboto pertentage-text">{{ Math.round(score) }}%</span>
      </v-progress-circular>
    </v-row>
    <v-row
      width="100%"
      justify="center"
      align="center"
      class="mb-6 ma-0 pa-0 tway-violet--text font-weight-bold title-card-text"
    >
      {{ level }}
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    score: {
      default: 0,
      type: Number,
      required: true,
    },
    level: {
      default: "",
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.title-card-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
}

.pertentage-text {
  color: #4a148c;
  font-size: 36px;
  line-height: 82.03px;
  text-align: center;
  font-weight: 700;
}
</style>
