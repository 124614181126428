<template>
  <v-card class="pa-5" height="100%">
    <div class="center-vertical-card">
      <div>
        <div class="tway-violet--text title-card-text">
          {{ dimension.name }}
        </div>
        <BarsItems :values="dimension.subDimensions" />
      </div>

      <span class="py-3 roboto subtitle-1" style="white-space: pre-line; word-wrap: break-word">
        {{ dimension.advice }}</span
      >
      <div class="card-vertical-button py-3">
        <v-btn large rounded color="tway-violet" class="white--text roboto" @click="setDimensionValue(dimension.code)">
          VER {{ dimension.name }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import BarsItems from "@/components/dashboards/IMTD/BarsItems";

export default {
  components: {
    BarsItems,
  },
  props: {
    dimension: {
      default: () => {},
      type: Object,
      required: true,
    },
  },
  methods: {
    setDimensionValue(id) {
      this.$emit("dimensionId", id);
    },
  },
};
</script>

<style lang="scss">
.center-vertical-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
  .card-vertical-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
