<template>
  <v-card height="100%" class="pa-0 recommendations-card" color="#fafafa">
    <v-row width="100%" justify="center" align="center" class="mb-2 ma-0 pa-0 tway-violet--text">
      <v-tabs v-model="tab" background-color="tway-violet" dark class="dimension-tabs">
        <v-tab
          v-for="(subdimension, index) in subdimensions"
          :key="index"
          @click="getSubDimensionCode(subdimension.code)"
          style="text-transform: initial"
          class="tab-text px-10"
        >
          {{ subdimension.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items style="width: 100% !important" v-model="tab">
        <v-tab-item v-for="(subdimension, index) in subdimensions" :key="index">
          <v-card class="tab-dashboard-item pa-5" elevation="0">
            <v-card-text class="my-0 py-0 px-6">
              <v-row
                class="roboto mb-0"
                style="white-space: pre-line; word-wrap: break-word; line-height: 24px; color: #333333"
              >
                {{ subdimension.advice }}
              </v-row>
              <v-row class="mt-1">
                <v-col class="categories-title ml-0 pl-0 mb-1">
                  Recomendamos integrar estos servicios para acelerar la transformación de tu empresa
                </v-col>
              </v-row>

              <v-row
                width="100%"
                justify="start"
                align="center"
                class="mb-0 mt-0"
                v-for="category in subdimensions[index].categories"
                :key="category"
              >
                <v-col class="ma-0 pa-0" cols="1">
                  <v-icon color="#16C62E"> mdi-arrow-right </v-icon>
                </v-col>
                <v-col
                  :class="desktop ? 'ml-n7' : 'ml-n1'"
                  class="ma-0 text-left roboto"
                  cols="11"
                  :style="desktop ? 'line-height: 4px' : 'line-height: 12px'"
                >
                  {{ category }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <v-row
      width="100%"
      justify="center"
      align="center"
      class="mb-3 ma-0 pa-0 tway-violet--text"
      style="background-color: #fafafa"
    >
      <v-btn rounded color="tway-violet" class="white--text roboto" @click="Projects()"> CREAR PROYECTOS </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    dimensionCode: {
      type: String,
      default: "",
      required: true,
    },
    subdimensions: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      subdimensionCode: "",
    };
  },
  created() {
    this.subdimensionCode = this.subdimensions[0].code;
  },
  methods: {
    getSubDimensionCode(code) {
      this.subdimensionCode = code;
    },
    Projects() {
      this.$router.push({
        name: "project-foundation",
        query: {
          dimension: this.dimensionCode,
          subdimension: this.subdimensionCode,
        },
      });
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss">
.recommendations-card ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: #fafafa;
}

.recommendations-card ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.tab-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
}
.tab-dashboard-item {
  background: #fafafa;
  /*height: 200px;*/
  /*height: 100%;*/
  height: 235px;
  overflow-y: scroll;
  overflow-x: scroll;
}
.categories-title {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.dimension-tabs {
  border-radius: 2px 2px 0 0;
}
</style>
